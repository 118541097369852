import {
  type InsurancePlanFull,
  practiceMgrApi,
  patientRegistrationApi,
  ClinicalAges,
  type PatientPreferenceValue,
  PatientPreferencesField,
  GetLocations,
  InsurancePlanStatus,
} from '@valerahealth/rtk-query'
import { useMemo } from 'react'
import { differenceInYears } from '../../../utils/date'

const { useLoadPatientBillingQuery, useGetPatientByTreatmentIdQuery } =
  patientRegistrationApi

const simpleLangMapping = new Map(
  Object.entries({
    zh: 'Mandarin',
    'zh-CN': 'Mandarin',
    'zh-TW': 'Mandarin',
    'zh-HK': 'Cantonese',
    es: 'Spanish',
    hi: 'Hindi',
    fr: 'French',
    de: 'German',
    ja: 'Japanese',
    it: 'Italian',
  }),
)

export function getAge(
  dateOfBirth: string | undefined | null,
):
  | Pick<PatientPreferenceValue, typeof PatientPreferencesField.clinicalAges>
  | undefined {
  if (!dateOfBirth) return {}
  const years = dateOfBirth
    ? -differenceInYears(new Date(dateOfBirth), new Date())
    : 0
  const value =
    years >= 65
      ? ClinicalAges.geriatric
      : years >= 18
      ? ClinicalAges.adult
      : years >= 13
      ? ClinicalAges.teens
      : ClinicalAges.peds
  return {
    [PatientPreferencesField.clinicalAges]: [value],
  }
}

function getInsuranceInfo(
  insurancePlanId: string | undefined | null,
  insurancePlans:
    | Pick<InsurancePlanFull, '_id' | 'stateCode' | 'name'>[]
    | undefined,
  locations: GetLocations['getLocations'] | undefined,
): Pick<
  PatientPreferenceValue,
  | typeof PatientPreferencesField.locations
  | typeof PatientPreferencesField.insurancePlan
> {
  const insurancePlan = insurancePlans?.find((ip) => ip._id === insurancePlanId)
  const stateCode = insurancePlan?.stateCode
  const location = locations?.find(
    (l) =>
      l.physicalType.code === 'telehealth' && l.address.state === stateCode,
  )?.id

  return {
    ...(location && {
      [PatientPreferencesField.locations]: [location],
    }),
    ...(insurancePlan && {
      [PatientPreferencesField.insurancePlan]: [insurancePlan._id],
    }),
  }
}

export function usePreferencePreset({
  treatmentId,
  skip,
}: {
  treatmentId: string | undefined
  skip: boolean
}): {
  isLoading: boolean
  patientPreferences: PatientPreferenceValue | null
} {
  const skipQueries = skip || !treatmentId

  const { data: patientProfileData, isLoading: isLoadingPatientProfile } =
    useGetPatientByTreatmentIdQuery(
      { treatmentId: treatmentId! },
      {
        skip: skipQueries,
        selectFromResult: ({ data, isLoading, isUninitialized }) => ({
          data: data?.getPatientByTreatmentId,
          isLoading: (isUninitialized && !skipQueries) || isLoading,
        }),
      },
    )

  const clinicalAges = useMemo(
    () => getAge(patientProfileData?.demographics?.dateOfBirth!),
    [patientProfileData?.demographics?.dateOfBirth],
  )

  const languagePreference =
    simpleLangMapping.get(
      patientProfileData?.demographics?.preferredLanguage || '',
    ) ||
    simpleLangMapping.get(
      patientProfileData?.demographics?.languages?.[0] || '',
    )

  const { locations, insurancePlans, isLoadingPMAggs } =
    practiceMgrApi.useGetPMAggregatesQuery(undefined, {
      skip: skipQueries,
      selectFromResult: ({ data, isLoading, isUninitialized }) => ({
        locations: data?.getLocations,
        insurancePlans: data?.getInsurancePlans || [],
        isLoadingPMAggs: (isUninitialized && !skipQueries) || isLoading,
      }),
    })

  const { data: billingData, isLoading: isLoadingBilling } =
    useLoadPatientBillingQuery(
      { input: { treatmentId } },
      {
        skip: skipQueries,
        selectFromResult: ({ data, isLoading, isUninitialized }) => ({
          data,
          isLoading: (isUninitialized && !skipQueries) || isLoading,
        }),
      },
    )

  const patientBillingPrimaryInsurance =
    billingData?.loadPatientBilling?.insurances?.[0]
  const insurancePlanId = patientBillingPrimaryInsurance?.outOfNetwork
    ? undefined
    : patientBillingPrimaryInsurance?.insurancePlanId

  const insuranceInfo = useMemo(
    () =>
      getInsuranceInfo(
        insurancePlanId,
        insurancePlans.filter(
          (plan) => plan.status === InsurancePlanStatus.Active,
        ),
        locations,
      ),
    [insurancePlanId, insurancePlans, locations],
  )

  const isLoading =
    !skipQueries &&
    (isLoadingPatientProfile || isLoadingPMAggs || isLoadingBilling)

  const patientPreferences = useMemo(() => {
    const value = isLoading
      ? null
      : {
          ...clinicalAges,
          ...(languagePreference
            ? { [PatientPreferencesField.languages]: [languagePreference] }
            : {}),
          ...insuranceInfo,
        }
    return value
  }, [isLoading, clinicalAges, languagePreference, insuranceInfo])

  return {
    patientPreferences,
    isLoading,
  }
}
