import { Tooltip } from '@mui/material'
import { CSSProperties } from 'react'

const nextGenIconURL = 'https://cdn.valerahealth.com/images/nextgen.png'

export const NextGenIcon = ({
  iconStyle,
  title,
  alt,
}: {
  iconStyle?: CSSProperties
  title?: string
  alt?: string
}) => (
  <Tooltip title={title}>
    <img src={nextGenIconURL} style={{ height: 15, ...iconStyle }} alt={alt} />
  </Tooltip>
)

export default NextGenIcon
