import { type ReactNode } from 'react'
import { SvgIconOwnProps } from '@valerahealth/ui-components/base'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import EventBusyIcon from '@mui/icons-material/EventBusy'
import Diversity1Icon from '@mui/icons-material/Diversity1'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1'
import WorkIcon from '@mui/icons-material/Work'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'
import RemoveModeratorIcon from '@mui/icons-material/RemoveModerator'
import PersonOffIcon from '@mui/icons-material/PersonOff'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ContentPasteIcon from '@mui/icons-material/ContentPaste'
import PendingActionsIcon from '@mui/icons-material/PendingActions'
import EventRepeatIcon from '@mui/icons-material/EventRepeat'
import CloseIcon from '@mui/icons-material/Close'
import EventIcon from '@mui/icons-material/Event'
import PersonIcon from '@mui/icons-material/Person'
import VideocamIcon from '@mui/icons-material/Videocam'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import PhoneIcon from '@mui/icons-material/Phone'
import AccessTimeIcon from '@mui/icons-material/AccessTimeFilled'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import WarningSharpIcon from '@mui/icons-material/WarningSharp'
import { SvgIconComponent } from '@mui/icons-material'
import { AppointmentStatus, ServiceCategoryCode } from '@valerahealth/rtk-query'
import { getAppointmentStatusColor } from './colors'

export {
  CloseIcon,
  PersonIcon,
  ContentPasteIcon,
  VideocamIcon,
  PriorityHighIcon,
  PhoneIcon,
  EditIcon,
  DeleteIcon,
}
export const PatientHourIcon: SvgIconComponent = PeopleAltIcon
export const OutOfOfficeIcon: SvgIconComponent = EventBusyIcon
export const GroupIcon: SvgIconComponent = Diversity1Icon
export const ConsultationIcon: SvgIconComponent = PersonAddAlt1Icon
export const CaseMnangementIcon: SvgIconComponent = WorkIcon
export const AdminIcon: SvgIconComponent = ManageAccountsIcon
export const VerifiedIcon: SvgIconComponent = VerifiedUserIcon
export const NotVerifiedIcon: SvgIconComponent = RemoveModeratorIcon
export const NoShowIcon: SvgIconComponent = PersonOffIcon
export const CompleteIcon: SvgIconComponent = CheckCircleIcon
export const DocumentCompleteIcon: SvgIconComponent = ContentPasteIcon
export const DocumentPendingIcon: SvgIconComponent = PendingActionsIcon
export const ShowProgressNoteIcon: SvgIconComponent = ContentPasteIcon
export const CanceledApointmentIcon: SvgIconComponent = EventBusyIcon
export const RescheduledAppointmentIcon: SvgIconComponent = EventRepeatIcon
export const ClockIcon: SvgIconComponent = AccessTimeIcon
export const ExpandMoreVerticalIcon: SvgIconComponent = MoreVertIcon
export const ConflictIcon: SvgIconComponent = WarningSharpIcon

export const AppointmentStatusIcon = ({
  status,
  ...rest
}: {
  status: AppointmentStatus
} & SvgIconOwnProps) => {
  const color = getAppointmentStatusColor(status)
  let Component: SvgIconComponent | undefined

  switch (status) {
    case AppointmentStatus.Fulfilled:
      Component = CompleteIcon
      break
    case AppointmentStatus.Rescheduled:
      Component = RescheduledAppointmentIcon
      break
    case AppointmentStatus.Canceled:
    case AppointmentStatus.Canceled_24:
    case AppointmentStatus.Canceled_48:
      Component = CanceledApointmentIcon
      break
    case AppointmentStatus.Noshow:
      Component = NoShowIcon
      break
    case AppointmentStatus.Proposed:
      Component = ClockIcon
      break
    case AppointmentStatus.Pending:
      Component = EventIcon
      break
    default:
  }

  if (!Component) return null
  return <Component color={color} {...rest} />
}

export const ApppointmentServiceIcon = ({
  serviceCategory,
  ...rest
}: {
  serviceCategory: ServiceCategoryCode
} & SvgIconOwnProps) => {
  let Component: SvgIconComponent | undefined

  switch (serviceCategory) {
    case ServiceCategoryCode.OutOfOffice:
      Component = OutOfOfficeIcon
      break
    default:
  }

  if (!Component) return null
  return <Component {...rest} />
}

// in future story
export const billingStatusIconMapping: { [status: string]: ReactNode[] } = {
  eligibilityNotVerified: [
    <NotVerifiedIcon
      sx={{ color: '#D32F2F', width: '1rem', height: '1rem' }}
    />,
  ],
}
// in future story
export const progressNoteStatusIconMapping: { [status: string]: ReactNode[] } =
  {}
