import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'
import { createApi } from '@reduxjs/toolkit/query/react'
import { AUTH_REDUCER_KEY, RootStateWithAuth } from '@valerahealth/redux-auth'
import { ErrorResponse } from '@rtk-query/graphql-request-base-query/dist/GraphqlBaseQueryTypes'
import {
  api as endpoints,
  SearchCreatedLastModifiedDocument,
} from './generated'
import {
  AuditRecordFragment,
  SearchCreatedLastModified,
  SearchCreatedLastModifiedVariables,
} from '../../shared/generated.types'

const baseApi = createApi({
  reducerPath: 'auditApi',
  keepUnusedDataFor: 0,
  tagTypes: [],
  baseQuery: graphqlRequestBaseQuery({
    url: `https://${process.env.AUDIT_API_DOMAIN}/graphql`,
    prepareHeaders: (headers, api) => {
      const state = api.getState() as RootStateWithAuth
      const { isAuthenticated, session } = state[AUTH_REDUCER_KEY]
      if (isAuthenticated) {
        headers.set('Authorization', `Bearer ${session?.accessToken.jwt}`)
      }
      return headers
    },
    customErrors: (error) => {
      console.error(error.response)
      const e = error.response.errors?.[0] as any
      const response: ErrorResponse = {
        name: e?.errorType || '',
        message: e?.message || '',
        stack: error.stack || '',
      }
      return response
    },
  }),
  endpoints,
})

export type CreatedAndLastModifiedQueryRes = {
  created: AuditRecordFragment | null
  lastUpdated: AuditRecordFragment[] | null
}

export type CreatedAndLastModiedQueryVariables = {
  apiName: string // name of api
  updateMutations: string[] // list all mutations used to update entity
  createMutations: string[] // list all mustations used to create entity
  objectId: string
  numberOfModifiedEvents?: number // amount of modified events you want back
}

export const auditApi = baseApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    getCreatedAndLastUpdatedAuditInfo: build.query<
      CreatedAndLastModifiedQueryRes,
      CreatedAndLastModiedQueryVariables
    >({
      query: ({
        apiName,
        updateMutations,
        createMutations,
        objectId,
        numberOfModifiedEvents,
      }) => {
        const sort = [
          {
            requestTimestamp: 'desc',
          },
          {
            _id: 'desc',
          },
        ]

        const variables: SearchCreatedLastModifiedVariables = {
          sort: JSON.stringify(sort),
          modifiedPageSize: numberOfModifiedEvents ?? 1,
          queryCreated: JSON.stringify({
            must: [
              {
                term: {
                  'apiName.keyword': apiName,
                },
              },
              {
                terms: {
                  'endpoint.keyword': createMutations,
                },
              },
              {
                match: {
                  metaDataString: objectId,
                },
              },
            ],
          }),
          queryModified: JSON.stringify({
            must: [
              {
                term: {
                  'apiName.keyword': apiName,
                },
              },
              {
                terms: {
                  'endpoint.keyword': updateMutations,
                },
              },
              {
                match: {
                  argumentsString: objectId,
                },
              },
            ],
          }),
        }

        return {
          document: SearchCreatedLastModifiedDocument,
          variables,
        }
      },
      transformResponse: (
        response: SearchCreatedLastModified,
      ): CreatedAndLastModifiedQueryRes => {
        return {
          created: response.createdEvents.audits[0] || null,
          lastUpdated: response.updatedEvents.audits || null,
        }
      },
    }),
  }),
})
