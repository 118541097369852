import {
  useState,
  useMemo,
  type MutableRefObject,
  Dispatch,
  SetStateAction,
} from 'react'
import {
  Save as SaveIcon,
  StarBorderOutlined,
  StarOutlined,
  MoreVert,
  SaveAs,
} from '@mui/icons-material'
import Autocomplete from '@mui/material/Autocomplete'
import { useSelector, useDispatch } from 'react-redux'
import {
  type GridApi,
  type GridColDef,
  type GridValidRowModel,
  type GridInitialState,
  GridLogicOperator,
} from '@mui/x-data-grid-pro'
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro'
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  FormControlLabel,
  Button,
  InputAdornment,
  Box,
  Stack,
  SxProps,
} from '../../base'
import { sortOnProperty } from '../../utils'
import {
  type RootStateWithPersistentGrid,
  persistentDataGridActions,
  PersistentGridView,
  selectPersistentGridViewSettings,
  UpsertViewPayload,
} from './reduxSlice'
import { useTranslation } from '../../utils/hooks'
import { useEnhancedSortRef } from '../enhancedSort'
import { useGridViewParams } from './useGridViewParams'
import { useNotify } from '../../features/Notifications'

type ViewDialogStateType = {
  form: {
    name: string
    isDefault?: boolean | null
  }
  nameIsNotUniqueErr: boolean
  nameIsEmptyErr: boolean
} & (
  | {
      mode: 'create'
      viewName?: undefined
    }
  | {
      mode: 'edit'
      viewName: string
    }
)

const initialDialogValue: ViewDialogStateType = {
  mode: 'create',
  form: {
    name: '',
    isDefault: false,
  },
  nameIsNotUniqueErr: false,
  nameIsEmptyErr: false,
}

export type GridViewsProps<T extends GridValidRowModel> = {
  apiRef: MutableRefObject<GridApi>
  gridName: string
  columns: GridColDef<T>[]
  columnOrdering?: {
    orderedFields: string[]
    setOrderedFields: Dispatch<SetStateAction<string[] | undefined>>
  }
  presetGridViews?: PersistentGridView
  defaultGridState?: GridInitialState
  onSave?: (paylod: UpsertViewPayload) => UpsertViewPayload
  persistQuickSearchFilter?: boolean
  disableSave?: boolean
  sx?: SxProps
  updateExternalStates?: (
    state: GridInitialStatePro,
    viewName?: string | null,
  ) => void
}

type Option = {
  name: string
  isDefault: boolean
  group:
    | 'persistentDataGrid.savedViewsGroupLabel'
    | 'persistentDataGrid.presetViewsGroupLabel'
}

export default function GridViews<T extends GridValidRowModel>({
  gridName,
  apiRef,
  columns,
  persistQuickSearchFilter,
  presetGridViews = {},
  defaultGridState,
  onSave = (val) => val,
  disableSave,
  sx,
  updateExternalStates,
  columnOrdering,
}: GridViewsProps<T>) {
  const notify = useNotify()
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const enhancedSortRef = useEnhancedSortRef()
  const [viewDialogOpen, toggleCreateViewDialogOpen] = useState(false)
  const [viewDialogState, setViewDialogState] = useState(initialDialogValue)
  const persistentGridViewSetting = useSelector(
    (state: RootStateWithPersistentGrid) =>
      selectPersistentGridViewSettings(state, gridName),
  )

  const { selectedView, selectView } = useGridViewParams()

  const { views, defaultView } = persistentGridViewSetting
  const options: Option[] = useMemo(() => {
    return sortOnProperty(
      Object.keys(views).map((name) => {
        const option: Option = {
          name,
          isDefault: name === defaultView,
          group: 'persistentDataGrid.savedViewsGroupLabel',
        }
        return option
      }),
      'name',
    ).concat(
      sortOnProperty(
        Object.keys(presetGridViews).map((name) => {
          const option: Option = {
            name,
            isDefault: name === defaultView,
            group: 'persistentDataGrid.presetViewsGroupLabel',
          }
          return option
        }),
        'name',
      ),
    )
  }, [views, presetGridViews, defaultView])

  const handleViewChange = (viewName?: string | null) => {
    // update url params
    selectView(viewName)

    let state: GridInitialStatePro

    if (!viewName) {
      // reset orderedFields
      if (columnOrdering) {
        columnOrdering.setOrderedFields(undefined)
      }
      // reset to defaultGridState if view was cleared
      state = {
        filter: {
          filterModel: defaultGridState?.filter?.filterModel || { items: [] },
        },
        sorting: { sortModel: defaultGridState?.sorting?.sortModel || [] },
        columns: {
          columnVisibilityModel:
            defaultGridState?.columns?.columnVisibilityModel || {},
        },
        pinnedColumns: {
          left: defaultGridState?.pinnedColumns?.left || [],
          right: defaultGridState?.pinnedColumns?.right || [],
        },
      }
    } else {
      // update enhancedSortRef and state
      const view = { ...views, ...presetGridViews }[viewName]!
      if (
        columnOrdering &&
        view.additionalMeta &&
        'orderedFields' in view.additionalMeta
      ) {
        columnOrdering.setOrderedFields(view.additionalMeta.orderedFields)
      }
      enhancedSortRef.current = { ...view.enhancedSortModel }
      state = view.gridState
    }
    // apply updates
    apiRef.current.restoreState(state)
    if (updateExternalStates) {
      updateExternalStates(state, viewName)
    }
  }

  const isPresetViewSelected = useMemo(() => {
    return !!selectedView && !!presetGridViews[selectedView]
  }, [presetGridViews, selectedView])

  const handleViewDialogClose = () => {
    setViewDialogState(() => initialDialogValue)
    toggleCreateViewDialogOpen(false)
  }

  const handleSave = (viewName: string, isDefault: boolean) => {
    // dont want pagination to be persisted
    const { pagination, ...gridState } = apiRef.current.exportState()
    const visibleColumns = apiRef.current.getVisibleColumns()
    const columnVisibilityModel = columns.reduce((sum, val) => {
      const visible = visibleColumns.some(({ field }) => field === val.field)
      if (!visible) {
        sum[val.field] = false
      }
      return sum
    }, {} as Record<string, boolean>)

    dispatch(
      persistentDataGridActions.upsertView(
        onSave({
          gridName,
          viewName,
          isDefault,
          viewSettings: {
            enhancedSortModel: { ...enhancedSortRef.current },
            gridState: {
              pinnedColumns: {
                left: gridState.pinnedColumns?.left || [],
                right: gridState.pinnedColumns?.right || [],
              },
              columns: {
                ...gridState.columns,
                columnVisibilityModel,
              },
              filter: {
                filterModel: {
                  ...(gridState.filter?.filterModel || {}),
                  items: gridState.filter?.filterModel?.items || [],
                  logicOperator:
                    gridState.filter?.filterModel?.logicOperator ||
                    GridLogicOperator.And,
                  quickFilterValues: persistQuickSearchFilter
                    ? gridState.filter?.filterModel?.quickFilterValues
                    : [],
                },
              },
              sorting: {
                sortModel: [...(gridState.sorting?.sortModel || [])],
              },
            },
            additionalMeta: {
              ...(columnOrdering
                ? { orderedFields: columnOrdering.orderedFields }
                : {}),
            },
          },
        }),
      ),
    )
    notify({
      severity: 'success',
      message: t('persistentDataGrid.viewSavedSuccess', { name: viewName }),
    })
  }

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const { mode, form } = viewDialogState

    const name = form.name.trim()

    if (!name) {
      setViewDialogState({ ...viewDialogState, nameIsEmptyErr: true })
      return
    }

    if (presetGridViews[name]) {
      setViewDialogState((v) => ({
        ...v,
        nameIsNotUniqueErr: true,
      }))
      return
    }

    if (mode === 'create') {
      // persists the view to redux
      handleSave(name, !!form.isDefault)
      selectView(name)
    } else {
      // editing an existing view
      const updates = {
        isDefault: form.isDefault,
      } as {
        isDefault: boolean
        name?: string
      }
      // ensures we only update the name if we've changed it
      if (name !== viewDialogState.viewName) {
        updates.name = name
      }
      dispatch(
        persistentDataGridActions.updateView({
          gridName,
          viewName: viewDialogState.viewName,
          updates,
        }),
      )

      // if we have changed the name of a view that is selected then change the selected view to the new name
      if (updates.name && selectedView === viewDialogState.viewName) {
        selectView(updates.name)
      }
    }
    // sets the new saved view as the selected value
    handleViewDialogClose()
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      flexWrap="nowrap"
      gap={1}
      sx={{ ...sx }}
    >
      <Autocomplete
        fullWidth
        size="small"
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        forcePopupIcon={false}
        openOnFocus
        isOptionEqualToValue={(o, v) => o.name === v.name}
        options={options}
        value={options.find((o) => o.name === selectedView) || null}
        getOptionLabel={(option) => option.name}
        groupBy={(o) => t(o.group)}
        onChange={(
          _: React.SyntheticEvent<Element, Event>,
          newValue: Option | null,
        ) => {
          handleViewChange(newValue?.name)
        }}
        renderOption={(props, option) => {
          const isPresetView = !!presetGridViews[option.name]
          return (
            <ListItem {...props}>
              <IconButton
                edge="start"
                color="success"
                size="small"
                title={
                  option.isDefault
                    ? t('persistentDataGrid.defaultView')
                    : t('persistentDataGrid.setAsDefault')
                }
                placement="right"
                onClick={(e) => {
                  e.stopPropagation()
                  dispatch(
                    persistentDataGridActions.setDefaultView({
                      gridName,
                      viewName: option.isDefault ? null : option.name,
                    }),
                  )
                }}
              >
                {option.isDefault ? <StarOutlined /> : <StarBorderOutlined />}
              </IconButton>
              <ListItemText primary={option.name} />
              {!isPresetView && (
                <ListItemIcon>
                  <IconButton
                    size="small"
                    title={t('persistentDataGrid.editView')}
                    placement="right"
                    onClick={(e) => {
                      e.stopPropagation()
                      setViewDialogState({
                        mode: 'edit',
                        viewName: option.name,
                        form: {
                          name: option.name,
                          isDefault: option.isDefault,
                        },
                        nameIsEmptyErr: false,
                        nameIsNotUniqueErr: false,
                      })
                      toggleCreateViewDialogOpen(true)
                    }}
                  >
                    <MoreVert />
                  </IconButton>
                </ListItemIcon>
              )}
            </ListItem>
          )
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              label={t('persistentDataGrid.savedViewsTextFieldLabel')}
              placeholder={t(
                'persistentDataGrid.savedViewsTextFieldPlaceholder',
              )}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{ position: 'absolute', right: 1 }}
                  >
                    <Box sx={{ position: 'relative', right: '-0.75rem' }}>
                      {params.InputProps.endAdornment}
                    </Box>
                    <IconButton
                      title={t('save')}
                      disableFocusRipple
                      onClick={(e) => {
                        e.stopPropagation()
                        if (selectedView)
                          handleSave(selectedView, defaultView === selectedView)
                      }}
                      disabled={
                        disableSave || !selectedView || isPresetViewSelected
                      }
                    >
                      <SaveIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )
        }}
      />

      <IconButton
        title={t('saveAs')}
        disabled={disableSave}
        onClick={() => {
          setViewDialogState(() => ({
            mode: 'create',
            form: {
              name: '',
              isDefault: false,
            },
            nameIsEmptyErr: false,
            nameIsNotUniqueErr: false,
          }))
          toggleCreateViewDialogOpen(true)
        }}
      >
        <SaveAs />
      </IconButton>

      <Dialog
        open={viewDialogOpen}
        onClose={handleViewDialogClose}
        fullWidth
        maxWidth="xs"
      >
        <form onSubmit={handleFormSubmit}>
          <DialogTitle>
            {viewDialogState.mode === 'create'
              ? t('persistentDataGrid.viewDialogTitleAdd')
              : t('persistentDataGrid.viewDialogTitleEdit', {
                  name: viewDialogState.viewName,
                })}
          </DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              autoFocus
              margin="dense"
              value={viewDialogState.form.name}
              error={
                viewDialogState.nameIsEmptyErr ||
                viewDialogState.nameIsNotUniqueErr
              }
              helperText={
                viewDialogState.nameIsEmptyErr
                  ? t('persistentDataGrid.viewDialogNameIsEmptyErr')
                  : viewDialogState.nameIsNotUniqueErr
                  ? t('persistentDataGrid.viewDialodNameIsNotUniqueErr', {
                      name: viewDialogState.form.name.trim(),
                    })
                  : ''
              }
              onChange={(event) =>
                setViewDialogState((state) => {
                  const value = event.target.value.trim()
                  return {
                    ...state,
                    form: {
                      ...state.form,
                      name: event.target.value,
                    },
                    // give instant feedback
                    nameIsNotUniqueErr:
                      !!presetGridViews[value] ||
                      // then name has been changed but the changed value exists in views list
                      (value !== viewDialogState.viewName && !!views[value]),
                    // only error on submit, but turn off on change
                    nameIsEmptyErr: !value ? state.nameIsEmptyErr : false,
                  }
                })
              }
              label={t('name')}
              type="text"
              variant="standard"
            />
            <FormControlLabel
              control={<Checkbox checked={!!viewDialogState.form.isDefault} />}
              label={t('persistentDataGrid.isDefault')}
              onChange={(event, checked) =>
                setViewDialogState({
                  ...viewDialogState,
                  form: {
                    ...viewDialogState.form,
                    isDefault: checked,
                  },
                })
              }
            />
          </DialogContent>
          <DialogActions>
            {viewDialogState.mode === 'edit' && (
              <Button
                color="error"
                variant="text"
                sx={{ marginRight: 'auto' }}
                onClick={() => {
                  dispatch(
                    persistentDataGridActions.deleteView({
                      gridName,
                      viewName: viewDialogState.viewName,
                    }),
                  )
                  if (selectedView === viewDialogState.viewName) {
                    handleViewChange(null)
                  }
                  handleViewDialogClose()
                }}
              >
                {t('delete')}
              </Button>
            )}
            <Button
              variant="text"
              color="inherit"
              onClick={handleViewDialogClose}
            >
              {t('cancel')}
            </Button>
            <Button
              color="success"
              type="submit"
              disabled={viewDialogState.nameIsNotUniqueErr}
            >
              {t('save')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Stack>
  )
}
