import {
  IconButton,
  TextField as BaseTextField,
  Stack,
  PopupMenu,
  LinearProgress,
} from '@valerahealth/ui-components/base'
import { PersonSearchOutlined as PersonSearchIcon } from '@valerahealth/ui-components/icons'
import { auditApi, userAdminApi } from '@valerahealth/rtk-query'
import { useMemo } from 'react'
import { formatTz } from '@valerahealth/ui-components/utils/date'

export type AppointmentAuditInfoButtonProps = {
  appointmentId: string
}

export function AppointmentAuditInfoContent({
  appointmentId,
}: AppointmentAuditInfoButtonProps) {
  const { data: auditInfo, isFetching: isLoadingAudit } =
    auditApi.useGetCreatedAndLastUpdatedAuditInfoQuery(
      {
        apiName: 'scheduling_api',
        createMutations: ['createAppointment', 'createAppointmentV2'],
        updateMutations: ['updateAppointment'],
        objectId: appointmentId,
        numberOfModifiedEvents: 1,
      },
      {
        selectFromResult: ({ currentData, isFetching }) => ({
          data: currentData,
          isFetching,
        }),
      },
    )

  const [createdUserId, lastModifiedUserId] = [
    auditInfo?.created?.caller.cognitoId,
    auditInfo?.lastUpdated?.[0]?.caller.cognitoId,
  ]

  const ids = [createdUserId, lastModifiedUserId].filter(
    (v): v is string => !!v,
  )

  const { data: usrs, isLoading: isLoadingSmry } =
    userAdminApi.useDescribeUsersByCognitoIdQuery(
      {
        ids,
      },
      {
        skip: !ids.length,
        selectFromResult: ({ data, isLoading }) => ({ data, isLoading }),
      },
    )

  const { createdUser, updatedUser } = useMemo(() => {
    return {
      createdUser:
        usrs?.describeUsersByCognitoUserId.find(
          (v) => v.cognitoUserId === createdUserId,
        )?.display.expandedName || '',
      updatedUser:
        usrs?.describeUsersByCognitoUserId.find(
          (v) => v.cognitoUserId === lastModifiedUserId,
        )?.display.expandedName || '',
    }
  }, [usrs, createdUserId, lastModifiedUserId])

  return (
    <Stack p={2} gap={2} minWidth="20rem">
      {(isLoadingAudit || isLoadingSmry) && <LinearProgress />}
      <BaseTextField
        label="Created By"
        value={createdUser}
        fullWidth
        InputProps={{ readOnly: true }}
        InputLabelProps={{
          shrink: true,
        }}
        variant="standard"
      />
      <BaseTextField
        label="Date & Time"
        value={
          auditInfo?.created?.requestTimestamp
            ? formatTz(
                new Date(auditInfo?.created?.requestTimestamp),
                'MM/dd/yyyy, h:mm a z',
              )
            : ''
        }
        fullWidth
        InputProps={{ readOnly: true }}
        InputLabelProps={{
          shrink: true,
        }}
        variant="standard"
      />
      <BaseTextField
        label="Last Modified By"
        value={updatedUser}
        fullWidth
        InputProps={{ readOnly: true }}
        InputLabelProps={{
          shrink: true,
        }}
        variant="standard"
      />
      <BaseTextField
        label="Date & Time"
        value={
          auditInfo?.lastUpdated?.[0]?.requestTimestamp
            ? formatTz(
                new Date(auditInfo?.lastUpdated?.[0]?.requestTimestamp),
                'MM/dd/yyyy, h:mm a z',
              )
            : ''
        }
        fullWidth
        InputProps={{ readOnly: true }}
        InputLabelProps={{
          shrink: true,
        }}
        variant="standard"
      />
    </Stack>
  )
}

export function AppointmentAuditInfoButton({
  appointmentId,
}: AppointmentAuditInfoButtonProps) {
  return (
    <PopupMenu
      closeOnClick={false}
      transformOrigin={{
        horizontal: 'left',
        vertical: 'top',
      }}
      button={
        <IconButton title="History">
          <PersonSearchIcon />
        </IconButton>
      }
      keepMounted={false}
    >
      <AppointmentAuditInfoContent appointmentId={appointmentId} />
    </PopupMenu>
  )
}
