import {
  AppointmentStatus,
  ProviderServiceType,
  ServiceCategoryCode,
  ServiceTypeCode,
  SessionType,
} from '@valerahealth/rtk-query'

export const SCHEDULING_GUIDELINE_LINK =
  'https://valerahealth.atlassian.net/servicedesk/customer/portal/2/article/292683777?src=1573724613'
export const DRCHRONO_DOMAIN = 'app.drchrono.com'
export const DRCHRONO_ICON_LINK =
  'https://cdn.valerahealth.com/images/drchrono.png'

export const APPOINTMENT_VIRTUAL_VISIT_LINK =
  'https://connect.virtualvisits.nextgen.com/provider/visits'

export const CARD_GUTTER_WIDTH_REMS = 0.8
export const BACKGROUND_EVENT_BORDER_PX = 2
export const EVENT_BORDER_PX = 1
export const EVENT_GAP = 1

export const CANCELED_APPOINTMENT_STATUSES = [
  AppointmentStatus.Canceled,
  AppointmentStatus.Canceled_24,
  AppointmentStatus.Canceled_48,
  AppointmentStatus.EnteredInError,
  AppointmentStatus.Rescheduled,
]

export const CANCELED_APPOINTMENT_STATUSES_SET = new Set(
  CANCELED_APPOINTMENT_STATUSES,
)

export const EDIT_APPOINTMENT_STATUSES = [
  AppointmentStatus.Booked,
  AppointmentStatus.Fulfilled,
  AppointmentStatus.Noshow,
  AppointmentStatus.Canceled,
  AppointmentStatus.Canceled_48,
  AppointmentStatus.Rescheduled,
  AppointmentStatus.Proposed,
]

export const SESSION_TYPES = [
  SessionType.ValeraVideo,
  SessionType.VirtualVisit,
  SessionType.GoogleMeet,
  SessionType.InPerson,
  SessionType.Other,
]

export const THERAPY_SERVICE_TYPES = [
  ServiceTypeCode.Dbt,
  ServiceTypeCode.DbtInt,
  ServiceTypeCode.Emdr,
  ServiceTypeCode.EmdrInt,
  ServiceTypeCode.F30,
  ServiceTypeCode.F45,
  ServiceTypeCode.F60,
  ServiceTypeCode.Int,
  ServiceTypeCode.Int45,
  ServiceTypeCode.LocInt,
  ServiceTypeCode.TocInt,
  ServiceTypeCode.Trf,
]
export const GROUP_THERAPY_SERVICE_TYPES = [
  ServiceTypeCode.Grp,
  ServiceTypeCode.GrpInt,
]
export const PSYCHIATRY_SERVICE_TYPES = [
  ServiceTypeCode.PsyInt,
  ServiceTypeCode.PsyCapInt,
  ServiceTypeCode.PsyInc,
  ServiceTypeCode.PsyF20,
  ServiceTypeCode.PsyF30,
  ServiceTypeCode.PsyF45,
  ServiceTypeCode.PsyF60,
  ServiceTypeCode.PsyTrf,
  ServiceTypeCode.PsyTocInt,
]

export const BILLABLE_SERVICE_TYPES = [
  ServiceTypeCode.Medrec,
  ...THERAPY_SERVICE_TYPES,
  ...GROUP_THERAPY_SERVICE_TYPES,
  ...PSYCHIATRY_SERVICE_TYPES,
]

export const EXCLUDE_APPT_STATUS_FOR_WARNING = [
  AppointmentStatus.Canceled,
  AppointmentStatus.Canceled_24,
  AppointmentStatus.Canceled_48,
  AppointmentStatus.EnteredInError,
  AppointmentStatus.Rescheduled,
  AppointmentStatus.Waitlist,
]

export const NON_PATIENT_APPOINTMENT_SERVICE_TYPES = [
  ServiceTypeCode.Adm,
  ServiceTypeCode.Brk,
  ServiceTypeCode.Pto,
  ServiceTypeCode.Holiday,
]

export const PATIENT_APPOINTMENT_SERVICE_TYPES = Object.values(
  ServiceTypeCode,
).filter(
  (v) =>
    !NON_PATIENT_APPOINTMENT_SERVICE_TYPES.includes(v) &&
    v !== ServiceTypeCode.Other &&
    v !== ServiceTypeCode.Int45 &&
    v !== ServiceTypeCode.LocInt &&
    v !== ServiceTypeCode.PsyInc,
)

export const EMPHASIZED_APPOINTMENT_STATUSES = [
  AppointmentStatus.Canceled,
  AppointmentStatus.Canceled_24,
  AppointmentStatus.Canceled_48,
  AppointmentStatus.Noshow,
  AppointmentStatus.Fulfilled,
  AppointmentStatus.Rescheduled,
  AppointmentStatus.Pending,
  AppointmentStatus.Proposed,
]

export const CANCELLED_APPOINTMENT_STATUSES = new Set([
  AppointmentStatus.Canceled,
  AppointmentStatus.Canceled_24,
  AppointmentStatus.Canceled_48,
])

export const ADMINISTRATIVE_APPOINTMENTS_SET = new Set([
  ServiceCategoryCode.Admin,
  ServiceCategoryCode.Break,
  ServiceCategoryCode.OutOfOffice,
])

export const ADMIN_APPOINTMENT_CATEGORY_TO_TYPE_MAP = {
  [ServiceCategoryCode.Admin]: ServiceTypeCode.Adm,
  [ServiceCategoryCode.Break]: ServiceTypeCode.Brk,
  [ServiceCategoryCode.OutOfOffice]: ServiceTypeCode.Brk,
}

export const ADMINISTRATIVE_APPOINTMENTS_TYPES_SET = new Set([
  ServiceTypeCode.Adm,
  ServiceTypeCode.Brk,
])

export const PROVIDER_SERVICE_TYPES_TO_SERVICE_TYPE_CODE = {
  [ProviderServiceType.GroupTherapy]: [
    ServiceTypeCode.Grp,
    ServiceTypeCode.GrpInt,
  ],
  [ProviderServiceType.Therapy]: [
    ServiceTypeCode.Trf,
    ServiceTypeCode.Dbt,
    ServiceTypeCode.DbtInt,
    ServiceTypeCode.Emdr,
    ServiceTypeCode.EmdrInt,
    ServiceTypeCode.F30,
    ServiceTypeCode.F45,
    ServiceTypeCode.F60,
    ServiceTypeCode.Int,
    ServiceTypeCode.Tif,
    ServiceTypeCode.TocInt,
  ],
  [ProviderServiceType.MedMgmt]: [
    ServiceTypeCode.Medrec,
    ServiceTypeCode.PsyCapInt,
    ServiceTypeCode.PsyTrf,
    ServiceTypeCode.PsyF20,
    ServiceTypeCode.PsyF30,
    ServiceTypeCode.PsyF45,
    ServiceTypeCode.PsyF60,
    ServiceTypeCode.PsyInt,
    ServiceTypeCode.PsyTocInt,
  ],
}
