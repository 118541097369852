import { type Overwrite } from 'utility-types'
import {
  endOfMonth,
  startOfMonth,
  startOfWeek,
  endOfWeek,
  addDays,
  isMonday,
  isTuesday,
  isWednesday,
  isThursday,
  isFriday,
  isSaturday,
  isSunday,
  isWithinInterval,
  nextSunday,
  nextMonday,
  nextTuesday,
  nextWednesday,
  nextThursday,
  nextFriday,
  nextSaturday,
  addMinutes,
  isoTimeToMinutes,
  isFuture,
  differenceInHours,
  Interval,
  toZonedTime,
  set,
  fromZonedTime,
} from '@valerahealth/ui-components/utils/date'
import {
  type ScheduleFragment,
  type AppointmentFragment,
  ResourceType,
  DayOfWeek,
  ServiceTypeCode,
  ServiceCategoryCode,
  AppointmentIdentifierSource,
  AppointmentParticipantTypeCode,
  AppointmentStatus,
} from '@valerahealth/rtk-query'
import { type TFunction } from '@valerahealth/ui-translation'
import {
  type AppointmentEventType,
  type ScheduleEventType,
} from '../components/Calendar/Calendar.type'
import { getServiceCategoryColor, getServiceTypeColor } from './colors'
import {
  DRCHRONO_DOMAIN,
  CANCELED_APPOINTMENT_STATUSES,
  ADMINISTRATIVE_APPOINTMENTS_SET,
  ADMIN_APPOINTMENT_CATEGORY_TO_TYPE_MAP,
  ADMINISTRATIVE_APPOINTMENTS_TYPES_SET,
} from './constants'
import {
  AddAdminApptFormViewState,
  EditAppointmentView,
  PatientAppointmentServiceCategoryCodes,
  AddPatientApptFormViewState,
  SchedulingView,
  AdminAppointmentServiceCategoryCodes,
  AddAppointmentView,
  AdminAppointmentServiceTypeCodes,
  PatientAppointmentServiceTypeCodes,
} from '../reducer'

export function timeToMin(time: string) {
  const [h, m] = time.split(':').map((t) => parseInt(t, 10))
  return h! * 60 + m!
}

export const isPatientApptServiceType = (
  code: ServiceTypeCode,
): code is PatientAppointmentServiceTypeCodes =>
  !ADMINISTRATIVE_APPOINTMENTS_TYPES_SET.has(code)

export const isAdminApptServiceType = (
  code: ServiceTypeCode,
): code is AdminAppointmentServiceTypeCodes =>
  ADMINISTRATIVE_APPOINTMENTS_TYPES_SET.has(code)

export const getDrChronoAppointmentURL = (arg?: {
  patientEmrId?: string | null
  appointmentEmrId?: string | null
}) => {
  const { patientEmrId, appointmentEmrId } = arg || {}

  if (appointmentEmrId)
    return `https://${DRCHRONO_DOMAIN}/appointments/${appointmentEmrId}`
  if (patientEmrId)
    return `https://${DRCHRONO_DOMAIN}/appointments/new/?patient_id=${patientEmrId}`
  return `https://${DRCHRONO_DOMAIN}/appointments/new`
}

export const getDrChronoClinicalNoteURL = (drcId: string) =>
  `https://${DRCHRONO_DOMAIN}/clinical_note/edit/${drcId}`

export const getAppointmentLocation = (appointment: AppointmentFragment) =>
  appointment.participants.find(
    (person) => person.actor.type === ResourceType.Location,
  )?.actor

export const getAppointmentPatient = (appointment: AppointmentFragment) =>
  appointment.participants.find(
    (person) => person.actor.type === ResourceType.Patient,
  )?.actor

export const getAppointmentProvider = (appointment: AppointmentFragment) =>
  appointment.participants.find(
    (person) =>
      person.type?.code === AppointmentParticipantTypeCode.PrimaryPerformer,
  )?.actor

export const getAppointmentCoveringProvider = (
  appointment: AppointmentFragment,
) =>
  appointment.participants.find(
    (person) =>
      person.type?.code === AppointmentParticipantTypeCode.CoveringProvider,
  )?.actor

export const getAppointmentEmrId = (appointment: AppointmentFragment) =>
  appointment.identifiers?.find(
    (i) => i.source === AppointmentIdentifierSource.DrChrono,
  )?.value

export const dayOfWeekToWeekNum = {
  [DayOfWeek.Sunday]: { weekDay: 0, isToday: isSunday, nextDay: nextSunday },
  [DayOfWeek.Monday]: { weekDay: 1, isToday: isMonday, nextDay: nextMonday },
  [DayOfWeek.Tuesday]: { weekDay: 2, isToday: isTuesday, nextDay: nextTuesday },
  [DayOfWeek.Wednesday]: {
    weekDay: 3,
    isToday: isWednesday,
    nextDay: nextWednesday,
  },
  [DayOfWeek.Thursday]: {
    weekDay: 4,
    isToday: isThursday,
    nextDay: nextThursday,
  },
  [DayOfWeek.Friday]: { weekDay: 5, isToday: isFriday, nextDay: nextFriday },
  [DayOfWeek.Saturday]: {
    weekDay: 6,
    isToday: isSaturday,
    nextDay: nextSaturday,
  },
}

export const getWeekdaysInInterval = (
  interval: { start: Date; end: Date },
  isToday: (date: Date | number) => boolean,
  nextDay: (date: Date | number) => Date,
): Date[] => {
  const dates: Date[] = []
  let date: Date = interval.start
  if (isToday(date)) dates.push(date)
  date = nextDay(date)
  while (isWithinInterval(date, interval)) {
    dates.push(date)
    date = nextDay(date)
  }
  return dates
}

// Unused: Offset and interval. Monthly reoccurance.
export function scheduleToEvent(
  year: number,
  month: number,
  schedule: ScheduleFragment,
  t: TFunction<'scheduling', undefined>,
): ScheduleEventType[] {
  let events: ScheduleEventType[] = []
  const date = new Date(year, month, 15)
  const { timezone } = schedule.reoccuranceTemplate
  const viewDateInterval = {
    start: addDays(startOfWeek(startOfMonth(date)), -1),
    end: addDays(endOfWeek(endOfMonth(date)), 1),
  }
  let { startDate, endDate } = schedule.reoccuranceTemplate.planningHorizon
  if (!startDate) startDate = '2000-01-01'
  if (!endDate) endDate = '2100-12-31'

  const planningInterval = {
    start: toZonedTime(new Date(startDate), timezone),
    end: toZonedTime(new Date(endDate), timezone),
  }

  if (
    'weeklyTemplate' in schedule.reoccuranceTemplate &&
    schedule.reoccuranceTemplate.weeklyTemplate
  ) {
    schedule.reoccuranceTemplate.weeklyTemplate.forEach((wt) => {
      wt.dayTimes.forEach((dt) => {
        const weekdays = getWeekdaysInInterval(
          viewDateInterval,
          dayOfWeekToWeekNum[dt.dayOfWeek].isToday,
          dayOfWeekToWeekNum[dt.dayOfWeek].nextDay,
        )
        const newEvents: ScheduleEventType[] = weekdays
          .filter((d) => isWithinInterval(d, planningInterval))
          .map((d) => ({
            title: t(`ServiceCategoryCode.${schedule.serviceCategory.code}`),
            start: fromZonedTime(
              set(d, { minutes: timeToMin(dt.startTime) }),
              timezone,
            ),
            end: fromZonedTime(
              set(d, { minutes: timeToMin(dt.endTime) }),
              timezone,
            ),
            resource: {
              type: 'schedule',
              _schedule: schedule,
              ...getServiceCategoryColor(schedule.serviceCategory.code),
              // from old approval mocks applies lines to pending schedules
              backgroundAltStyle: false,
              serviceCategoryCode: schedule.serviceCategory.code,
            },
          }))
        events = events.concat(newEvents)
      })
    })
  }
  return events
}

const getSessionInfo = (appointment: AppointmentFragment) => {
  return (
    appointment.virtualService?.addressUrl ||
    appointment.virtualService?.phoneNumber ||
    undefined
  )
}

// not yet in appointments
// export const generateReoccuranceInfo = (
//   appointment: AppointmentFragment,
//   template: WeeklyTemplate | MonthlyTemplate | null | undefined,
//   t: TFunction<'scheduling', undefined>,
// ) => {
//   if (!template) return ''

//   if ('dayTimes' in template) {
//     const dayStr = template.dayTimes.map((dt) => t(dt.dayOfWeek)).join(', ')
//     /*
//     const startDateStr = formatString(
//       reoccuranceTemplete.planningHorizon.startDate,
//       'PP',
//     )
//     */
//     const endDateStr = formatString(
//       appointment.reoccuranceTemplate?.planningHorizon?.endDate,
//       'PP',
//     )
//     return `Weekly on ${dayStr}${endDateStr && ` until ${endDateStr}`}.`
//   }
//   /* DrC appointments don't have any monthly pattern. We'll leave this for furture story.
//   if (
//     reoccuranceTemplete.monthlyTemplate &&
//     reoccuranceTemplete.monthlyTemplate.length > 0
//   ) {

//   }
//   */
// }

const appointmentToEventMap = (
  appointment: AppointmentFragment,
  t: TFunction<'scheduling', undefined>,
): AppointmentEventType => {
  // we used to not have an OOO serviceTypeCode, but have recently added. this is to be backwards compatible

  const serviceType =
    appointment.serviceType?.code === ServiceTypeCode.Brk &&
    appointment.serviceCategory.code === ServiceCategoryCode.OutOfOffice
      ? ServiceTypeCode.Pto
      : appointment.serviceType?.code ?? ServiceTypeCode.Other

  const patient = getAppointmentPatient(appointment)
  const primaryProvider = getAppointmentProvider(appointment)
  const coveringProvider = getAppointmentCoveringProvider(appointment)

  const serviceTypeAcronym = t(`ServiceTypeCodeAcronym.${serviceType}`)
  const serviceTypeTitle = t(`ServiceTypeCode.${serviceType}`)

  const start = new Date(appointment.startDate)
  const end = new Date(appointment.endDate)

  return {
    // only really gets used for the title prop hovering an event card
    title: `${serviceTypeAcronym}${
      isPatientApptServiceType(serviceType)
        ? ` - ${t(`AppointmentStatus.${appointment.status}`)}`
        : ''
    }${patient?.display ? ` - ${patient.display}` : ''}`,
    start,
    end,
    resource: {
      type: 'appointment',
      _appointment: appointment,
      id: appointment._id,
      start,
      end,
      status: appointment.status,
      isCanceled: CANCELED_APPOINTMENT_STATUSES.includes(appointment.status),
      serviceType,
      serviceCategory: appointment.serviceCategory.code,
      // used on the event card
      serviceTypeAcronym:
        serviceType === ServiceTypeCode.Pto ||
        serviceType === ServiceTypeCode.Holiday
          ? `${serviceTypeAcronym}${
              appointment.description ? ` - ${appointment.description}` : ''
            }`
          : serviceTypeAcronym,
      // used in the appt details popup
      serviceTypeTitle:
        serviceType === ServiceTypeCode.Pto ||
        serviceType === ServiceTypeCode.Holiday
          ? `${serviceTypeTitle}${
              appointment.description ? ` - ${appointment.description}` : ''
            }`
          : serviceTypeTitle,
      palette: getServiceTypeColor(serviceType),
      patient,
      primaryProvider,
      coveringProvider,
      location: getAppointmentLocation(appointment) || undefined,
      emrId: getAppointmentEmrId(appointment) || undefined,
      sessionType: appointment.virtualService?.channelType,
      sessionInfo: getSessionInfo(appointment),
    },
  }
}

export const appointmentToEvent = (
  appointment: AppointmentFragment,
  t: TFunction<'scheduling', undefined>,
): AppointmentEventType => {
  return appointmentToEventMap(appointment, t)

  // if (!appointment.reoccuranceTemplate) {
  //   return [appointmentToEventMap(appointment, null, t)]
  // }
  // // for DrC appointment, it can have only one entry in weeklyTemplate array.
  // if (appointment.reoccuranceTemplate.weeklyTemplate) {
  //   return [
  //     appointmentToEventMap(
  //       appointment,
  //       appointment.reoccuranceTemplate.weeklyTemplate[0],
  //       t,
  //     ),
  //   ]
  // }
  // // no need for monthly reoccurance for now
  // return []
}

export const getIntervalWithDateAndTime = (input: {
  startDate?: Date | null
  startTime?: string | null
  endDate?: Date | null
  endTime?: string | null
}): Interval | undefined => {
  const { startDate, startTime, endDate, endTime } = input
  if (!(startDate && endDate && startTime && endTime)) return undefined
  try {
    const start = addMinutes(startDate, isoTimeToMinutes(startTime))
    const end = addMinutes(endDate, isoTimeToMinutes(endTime))
    return end > start
      ? {
          start,
          end,
        }
      : undefined
  } catch {
    return undefined
  }
}

export const isDrCAppt = (appt: AppointmentFragment) => {
  return !!getAppointmentEmrId(appt)
}

export const isAdministrativeAppt = (appt: AppointmentFragment) => {
  return ADMINISTRATIVE_APPOINTMENTS_SET.has(appt.serviceCategory.code)
}

export const getDurationOptionsForServiceType = (
  type?: ServiceTypeCode | null,
) => {
  let duration: number[] // all in minutes

  switch (type) {
    case ServiceTypeCode.Medrec:
      duration = [5]
      break
    case ServiceTypeCode.TocInt:
    case ServiceTypeCode.PsyTocInt:
      duration = [15, 30, 45, 60, 90]
      break
    case ServiceTypeCode.PsyInc:
    case ServiceTypeCode.PsyF20:
      duration = [20]
      break
    // case ServiceTypeCode.Con:
    case ServiceTypeCode.F30:
    case ServiceTypeCode.PsyF30:
      duration = [30]
      break
    case ServiceTypeCode.Dbt:
    case ServiceTypeCode.Emdr:
      duration = [30, 45, 60]
      break
    case ServiceTypeCode.Int45:
    case ServiceTypeCode.F45:
    case ServiceTypeCode.Trf:
    case ServiceTypeCode.Tif:
    case ServiceTypeCode.LocInt:
    case ServiceTypeCode.PsyF45:
    case ServiceTypeCode.PsyTrf:
      duration = [45]
      break
    case ServiceTypeCode.Int:
    case ServiceTypeCode.F60:
    case ServiceTypeCode.DbtInt:
    case ServiceTypeCode.EmdrInt:
    case ServiceTypeCode.GrpInt:
    case ServiceTypeCode.PsyInt:
    case ServiceTypeCode.PsyF60:
      duration = [60]
      break
    case ServiceTypeCode.Grp:
      duration = [60, 75, 90]
      break
    case ServiceTypeCode.PsyCapInt:
      duration = [90]
      break

    default:
      duration = []
      break
  }

  return duration
}

export const isAppointmentStatusDisabled = (
  startDate: Date,
  status: AppointmentStatus,
) => {
  switch (status) {
    case AppointmentStatus.Fulfilled:
    case AppointmentStatus.Noshow:
      return isFuture(startDate)
    case AppointmentStatus.Canceled_48: {
      return (
        //appointment is in the future
        isFuture(startDate) &&
        // less than 48 hours to start of appt
        differenceInHours(startDate, new Date(), { roundingMethod: 'ceil' }) >
          48
      )
    }
    default:
      return false
  }
}

export const isAdminAppointmentFormView = (
  view?: SchedulingView | null,
): view is
  | Overwrite<
      EditAppointmentView,
      {
        appointment: Overwrite<
          AppointmentFragment,
          {
            serviceCategory: {
              code: AdminAppointmentServiceCategoryCodes
            }
          }
        >
      }
    >
  | AddAdminApptFormViewState =>
  view?.type === 'appointmentForm' &&
  ((view.mode === 'edit' &&
    ADMINISTRATIVE_APPOINTMENTS_SET.has(
      view.appointment.serviceCategory.code,
    )) ||
    (view.mode === 'add' && ADMINISTRATIVE_APPOINTMENTS_SET.has(view.code)))

export const isPatientAppointmentFormView = (
  view?: SchedulingView | null,
): view is
  | Overwrite<
      EditAppointmentView,
      {
        appointment: Overwrite<
          AppointmentFragment,
          {
            serviceCategory: {
              code: PatientAppointmentServiceCategoryCodes
            }
          }
        >
      }
    >
  | AddPatientApptFormViewState =>
  view?.type === 'appointmentForm' &&
  ((view.mode === 'edit' &&
    !ADMINISTRATIVE_APPOINTMENTS_SET.has(
      view.appointment.serviceCategory.code,
    )) ||
    (view.mode === 'add' && !ADMINISTRATIVE_APPOINTMENTS_SET.has(view.code)))

export const isAddPatientAppointmentFormView = (
  view?: SchedulingView | null,
): view is AddPatientApptFormViewState =>
  view?.type === 'appointmentForm' &&
  view.mode === 'add' &&
  !ADMINISTRATIVE_APPOINTMENTS_SET.has(view.code)

export const isAddAdminAppointmentFormView = (
  view?: SchedulingView | null,
): view is AddAdminApptFormViewState =>
  view?.type === 'appointmentForm' &&
  view.mode === 'add' &&
  ADMINISTRATIVE_APPOINTMENTS_SET.has(view.code)

export const getApptViewProviderId = (view?: SchedulingView | null) => {
  if (isAddPatientAppointmentFormView(view))
    return view.initialState?.providerId || null
  if (isAddAdminAppointmentFormView(view)) return view.providerId || null
  return null
}

export const isPatientApptServiceCategory = (
  code: ServiceCategoryCode,
): code is PatientAppointmentServiceCategoryCodes =>
  !ADMINISTRATIVE_APPOINTMENTS_SET.has(code)

export const isAdminApptServiceCategory = (
  code: ServiceCategoryCode,
): code is AdminAppointmentServiceCategoryCodes =>
  ADMINISTRATIVE_APPOINTMENTS_SET.has(code)

export const getApptViewServiceCategory = (
  view: AddAppointmentView | EditAppointmentView,
) => (view.mode === 'add' ? view.code : view.appointment.serviceCategory.code)

export const getAdminApptServiceType = (
  serviceCategory: ServiceCategoryCode,
) => {
  return isAdminApptServiceCategory(serviceCategory)
    ? ADMIN_APPOINTMENT_CATEGORY_TO_TYPE_MAP[serviceCategory]
    : ServiceTypeCode.Other
}

// This method process the identifiers and returns an object for easy retrieval
export const getApptIdentifierDetails = (appointment: AppointmentFragment) => {
  const identifiers = Object.values(AppointmentIdentifierSource).reduce(
    (
      acc: Record<
        AppointmentIdentifierSource,
        {
          isAppt: boolean
          apptId: string | null
        }
      >,
      source: AppointmentIdentifierSource,
    ) => {
      const identifier = appointment.identifiers?.find(
        (x) => x.source === (source as AppointmentIdentifierSource),
      )

      acc[source] = {
        isAppt: identifier ? !!identifier.value : false,
        apptId: (identifier && identifier.value) || null,
      }

      return acc
    },
    {} as Record<
      AppointmentIdentifierSource,
      {
        isAppt: boolean
        apptId: string | null
      }
    >,
  )

  return (
    sources: AppointmentIdentifierSource | AppointmentIdentifierSource[],
  ) =>
    ([...sources] as Array<AppointmentIdentifierSource>).map(
      (source: AppointmentIdentifierSource) => identifiers[source],
    )
}
