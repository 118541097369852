import { ProviderServiceType, PriorAuthStatus } from '@valerahealth/rtk-query'
import { careTeamActionsEnum } from '../components/CareTeamNew/utilities'
import strings from '../strings.json'

export default {
  ...strings,
  copy: 'copy',
  providerServiceType: {
    CASE_MGMT: 'Case Mgmt',
    [ProviderServiceType.GroupTherapy]: 'Group',
    [ProviderServiceType.MedMgmt]: 'Psychiatry',
    [ProviderServiceType.Therapy]: 'Therpy',
  },
  patientTypeAheadInfo:
    'Add a Task to a Discharged Patient in the Patient Record only. Only Active Patients can be selected when adding Tasks from the Tasks Grid.',
  TreatmentSendFileFormLink: {
    text: 'Send a link to patient for upload',
    success: 'A link was sent to {{name}} at {{phone}}',
  },
  restore: 'Restore',
  ctm: 'CTM',
  you: 'You',
  associate_therapist: 'Associate Therapist',
  searchCareTeamMembers: 'Search Team Members...',
  careTeamMembers: 'Care Team Members',
  offUntil: 'Out off Office until',
  coveringUntil: 'Covering Until',
  pastCareTeamConversation: 'Past Care Team Conversations',
  [careTeamActionsEnum.ADD_CT_MEMBER]: 'Add Care Team Member',
  [careTeamActionsEnum.CHANGE_SERVICE_TYPE]: 'Change Service Type',
  [careTeamActionsEnum.MOVE_TO_PAST]: 'Move to Past',
  restoreConfirmation:
    'Are you sure you want to restore {name} to the current care team?',
  restoreConfirmationAlert:
    'This action will add the patient to their caseload and allow patient-provider chat.',
  isCoveringForThisCareTeamMember: 'is covering for this care team member',
  deleteCTM: 'Are you sure you want to delete {name}?',
  deleteCTMAlert:
    "This action is reserved for rare cases where this provider has been added to the care team in error. This action will delete all data related to this provider from the patient's profile, including their chat.",
  moveToPastConfirmation:
    'Are you sure you want to move {name} to the past care team?',
  moveToPastAlert:
    'This action will remove the patient from this provider’s caseload and make this chat inactive.',
  provider: 'Provider',
  disableOOOMessage:
    'Out of office is now managed in <Link>practice manager</Link>.',
  SelfPayNotice: {
    header: 'Self-Pay Notice',
    body1:
      'A patient is billed for services either by Self-Pay or through Insurance. If Self-Pay is checked, upon saving the updates to the billing record, any insurance data will be erased and the insurance data fields will be disabled, in both Care Manager and the EMR. This includes any data saved to Primary, Secondary, or Tertiary insurances.',
    body2:
      'In addition, if updating an existing patient that has completed a billable appointment, the insurance data with expiration date needs to be saved to the billing record in the EMR before checking Self-Pay so a history of the insurance is saved.',
  },
  SelfPayWarning: {
    header: 'Self-Pay Warning',
    body: "This patient has completed a billable appointment. Do not check Self-Pay unless the insurance data has been saved to the EMR's Insurance History.",
  },
  selfPayExplanation:
    'Insurance fields are disabled because Self-Pay is checked.',
  TreatmentBilling: {
    addressAllOrNone:
      'An address cannot be partially filled out. Please fill in this field or remove all address values',
  },
  leads: 'Leads',
  DischargePopup: {
    dischargePatientError: 'Failed to discharge the patient.',
    dischargePatientWarning:
      'You are about to discharge a patient. This action will revoke the patient’s access to the mobile application, and remove the patient and their data from any active lists on this clinic.',
    dischargePatientSuccess: 'Successfully discharged the patient.',
  },
  sendAppLink: 'Send App Download link',
  PatientAppInviteForm: {
    sendInvSuccess: 'Invitation sent.',
    sendInvError: 'An error occured while sending app invitation.',
  },
  insuranceVerificationNotSupportedNote_one:
    "The insurance plan {{notSupportedPlans, list}} is not supported by the verification sync. Please assign an Insurance Verification Task to a Billing Specialist to complete the patient's Insurance Verification.",
  insuranceVerificationNotSupportedNote_other:
    "The insurance plans {{notSupportedPlans, list}} are not supported by the verification sync. Please assign an Insurance Verification Task to a Billing Specialist to complete the patient's Insurance Verification.",
  TreatmentChannel: {
    chatDisabledMessage:
      'Chat is disabled for patients with a status of {{status}}',
  },
  billingType: 'Billing Type',
  ConsentStatus: {
    pending: 'Unread',
    agreed: 'Agreed',
    seen: 'Seen',
  },
  invalidMinimumPatientAge: 'The patient must be 12 years of age or above.',
  networkErrorTryAgain: 'Network Error, please try again later.',
  eligibilityReasons: 'Eligibility Reasons',
  patientEligibilityError:
    'A network error occured while verifying the patient eligibility.',
  PriorAuthStatus: {
    [PriorAuthStatus.Unknown]: 'Unknown',
    [PriorAuthStatus.Required]: 'Required',
    [PriorAuthStatus.NotRequired]: 'Not Required',
    [PriorAuthStatus.Satisfied]: 'Satisfied',
  },
  priorAuthorization: 'Prior Authorization',
  priorAuthorizationRequiredMsg: 'Prior Authorization is {{value}}',
  scheduleAppointment: 'Schedule Appointment',
  confirmDeleteApptBody:
    'Are you sure you want to delete this patient appointment?',
  apptDeleteSuccess: 'Appointment was successfully deleted.',
  viewBenefits: 'View Benefits',
  viewPDFBenefitsSummary: 'View PDF Benefits Summary',
  viewSummaryBenefitInpVerify: 'View Benefits Summary in pVerify',
  sendEmailSuccessMessage: 'Email sent successfully',
  sendEmailFailureMessage: 'Error in sending Benefit Summary via Email',
  dateNTime: 'Date & Time',
  sentToPatient: 'Sent to Patient',
  insuranceVerification: 'Insurance Verification',
  patientInsuranceData: 'Patient Insurance Data',
  payerPlanName: 'Plan Name',
  payerPlanType: 'Plan Type',
  deductible: 'Deductible',
  familyDeductible: 'Family Deductible',
  coinsurance: 'Coinsurance',
  payer: 'Payer',
  lastVerified: 'Last Verified on',
  insurancePolicyGroupName: 'Group Name',
  insurancePolicyGroupNumber: 'Group Number',
  subscriberData: 'Subscriber Data',
  valeraInsuranceData: 'Valera Insurance Data',
  insuranceIDN: 'ID Number',
  insuranceEffectiveDate: 'Effective Date',
  insuranceExpirationDate: 'Expiration Date',
  insuranceCoPay: 'Co-Pay',
  patientRelationshipInsured: 'Relationship',
  insuranceNotes: 'Insurance Notes',
}
