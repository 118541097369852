import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  type GridValidRowModel,
  type DataGridProProps,
  DataGridPro,
  useGridApiRef,
  type GridEventListener,
  type GridSlotsComponentsProps,
  type GridInitialState,
  GridColDef,
  GridColumnOrderChangeParams,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid-pro'
import { PersistentGridView } from './reduxSlice'
import Toolbar from './Toolbar'
import { usePersistentGridInitialState } from './useGridViewParams'
import { GridViewsProps } from './GridViews'

export type PersistentDataGridProps<T extends GridValidRowModel> = Omit<
  DataGridProProps<T>,
  'slotProps'
> & {
  /* used as redux key, defaults to window.location.pathname, if more than one needed on a page pass a name */
  name?: string
  presetGridViews?: PersistentGridView
  slotProps?: GridSlotsComponentsProps & {
    gridViews?: Omit<
      GridViewsProps<T>,
      'apiRef' | 'gridName' | 'columns' | 'presetGridViews' | 'defaultGridState'
    >
  }
  /** if using a server side grid you need to pass both initial state (may have a view selected) and default grid state (state when no view is selected) */
  defaultGridState?: GridInitialState
}

const orderColumns = (columns: GridColDef[], orderedFields?: string[]) => {
  if (!orderedFields) return columns
  const rank = orderedFields.reduce(
    (o, key, i) => ({ ...o, [key]: i }),
    {},
  ) as { [k: string]: number }
  return [...columns].sort((a, b) => rank[a.field]! - rank[b.field]!)
}

export default function PersistentDataGrid<T extends GridValidRowModel>(
  props: PersistentDataGridProps<T>,
) {
  const {
    name = window.location.pathname,
    apiRef: apiRefOverride,
    columns: _columns,
    onRowClick,
    presetGridViews,
    initialState,
    defaultGridState = initialState,
    slots,
    slotProps: _componentsProps,
    ...rest
  } = props
  const { gridViews: gridViewsProps, ...slotProps } = _componentsProps || {}
  const internalApiRef = useGridApiRef()
  const apiRef = apiRefOverride || internalApiRef

  const [panelStatus, setPanelStatus] = useState<boolean>(false)
  const [menuStatus, setMenuStatus] = useState<boolean>(false)
  const [orderedFields, setOrderedFields] = useState<string[] | undefined>(
    undefined,
  )
  // in edge case, the existing orderedFields might have different/out-of-date set of field names
  // If this is detected, ignore the existing ordering.
  const hasDiff = useMemo(() => {
    if (!orderedFields) return false
    const a = new Set(orderedFields)
    if (rest.checkboxSelection) a.delete(GRID_CHECKBOX_SELECTION_COL_DEF.field)
    const b = new Set(_columns.map((c) => c.field))
    const union = new Set([...a, ...b])
    return !(a.size === b.size && b.size === union.size)
  }, [_columns, orderedFields, rest.checkboxSelection])

  const columns = hasDiff
    ? _columns
    : orderColumns(_columns as GridColDef[], orderedFields)
  /** if user passed in their own intial state AND default state then this is not needed... user constructed initial state with this hook themself */
  const fallbackInitialState = usePersistentGridInitialState(
    name,
    defaultGridState,
    presetGridViews,
  )

  const onPreferencePanelOpen: GridEventListener<
    'preferencePanelOpen'
  > = () => {
    setPanelStatus(true)
  }
  const onPreferencePanelClose: GridEventListener<
    'preferencePanelClose'
  > = () => {
    setTimeout(() => setPanelStatus(false), 200) // wait for popup fades away animation to finish
  }
  const onMenuOpen: GridEventListener<'menuOpen'> = () => {
    setMenuStatus(true)
  }
  const onMenuClose: GridEventListener<'menuClose'> = () => {
    setTimeout(() => setMenuStatus(false), 200)
  }
  const onColumnOrderChange = useCallback((c: GridColumnOrderChangeParams) => {
    // move element from a to b
    setOrderedFields((o) => {
      if (!o) return undefined
      const targetIndex = c.targetIndex
      const oldIndex = o.findIndex((i) => i === c.column.field)
      if (targetIndex === oldIndex) return o
      if (targetIndex > oldIndex) {
        const left = [...o].slice(0, targetIndex + 1)
        const right = [...o].slice(targetIndex + 1)
        left.splice(oldIndex, 1)
        return [...left, o[oldIndex]!, ...right]
      }
      const left = [...o].slice(0, targetIndex)
      const right = [...o].slice(targetIndex)
      return [...left, o[oldIndex]!, ...right.filter((r) => r !== o[oldIndex])]
    })
  }, [])

  const popupStatus = panelStatus || menuStatus
  // get initial ordering
  useEffect(() => {
    if ((!rest.loading && !orderedFields) || hasDiff) {
      setOrderedFields([
        ...(rest.checkboxSelection
          ? [GRID_CHECKBOX_SELECTION_COL_DEF.field]
          : []),
        ..._columns.map((c) => c.field),
      ])
    }
  }, [_columns, hasDiff, orderedFields, rest.checkboxSelection, rest.loading])

  return (
    <DataGridPro
      showColumnVerticalBorder
      {...rest}
      disableColumnReorder={rest.loading || rest.disableColumnReorder}
      onRowClick={popupStatus ? () => {} : onRowClick}
      onPreferencePanelOpen={onPreferencePanelOpen}
      onPreferencePanelClose={onPreferencePanelClose}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
      onColumnOrderChange={onColumnOrderChange}
      apiRef={apiRef}
      columns={columns}
      slots={{ toolbar: Toolbar, ...slots }}
      initialState={
        initialState !== defaultGridState ? initialState : fallbackInitialState
      }
      slotProps={{
        ...slotProps,
        toolbar: {
          gridViewProps: {
            ...gridViewsProps,
            apiRef,
            gridName: name,
            columns,
            presetGridViews,
            defaultGridState,
            columnOrdering: { orderedFields, setOrderedFields },
          },
          ...slotProps?.toolbar,
        },
      }}
    />
  )
}
