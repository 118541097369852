import { CSSProperties } from 'react'
import { To } from 'react-router-dom'
import LumaIconSVG from '../../images/luma.svg'
import { Link, Tooltip } from '../../base'

export const LumaIcon = ({
  lumaId,
  lumaApptId,
  iconStyle,
  title,
}: {
  lumaId?: string
  lumaApptId?: string
  iconStyle?: CSSProperties
  title?: string
}) => {
  const linkTo: To = (() => {
    let linkTo = ''
    if (lumaId) linkTo = `https://next.lumahealth.io/patients/${lumaId}/`
    if (lumaApptId)
      linkTo = `https://next.lumahealth.io/schedule?appointmentId=${lumaApptId}`
    return linkTo
  })()

  return linkTo ? (
    <Link
      to={linkTo}
      target="_blank"
      onClick={(e) => {
        e.stopPropagation()
      }}
      underline="none"
      mx={1}
      sx={{
        m: 0,
        p: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      title={title}
    >
      <LumaIconSVG style={{ height: 15, ...iconStyle }} />
    </Link>
  ) : (
    <Tooltip title={title}>
      <span>
        <LumaIconSVG style={{ height: 15, ...iconStyle }} />
      </span>
    </Tooltip>
  )
}

export default LumaIcon
