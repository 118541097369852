import { useCallback, useMemo } from 'react'
import {
  FormProvider,
  useForm,
  DatePicker,
  validate,
  SaveButton,
  TextField,
  ProviderSelect,
  TimePicker,
  Select,
} from '@valerahealth/ui-components/form'
import {
  Stack,
  useNotify,
  Button,
  Box,
  Typography,
  Tooltip,
  Comment,
  CircularProgress,
  Grid,
  WorkflowSidebarActions,
  WorkflowSidebarContent,
  MenuItem,
} from '@valerahealth/ui-components'
import {
  ServiceCategoryCode,
  AppointmentParticipantTypeCode,
  AppointmentInput,
  AppointmentType,
  AppointmentStatus,
  ResourceType,
  ServiceTypeCode,
  schedulingApi,
  ProviderMinimalFragment,
  AppointmentFragment,
} from '@valerahealth/rtk-query'
import {
  type TimeOption,
  type DateTimeTuple,
  isoDateTimeToTuple,
  compareDesc,
  dateTimeTupleToIsoDateStr,
  dateTimeTupleToDate,
  areIntervalsOverlapping,
  isPast,
  isFuture,
  format,
  getTimezoneValues,
  SYSTEM_TIMEZONE,
  getUSTimezone,
} from '@valerahealth/ui-components/utils/date'
import { Check, Close } from '@valerahealth/ui-components/icons'
import { useTranslation } from '../locales'
import {
  getAdminApptServiceType,
  getAppointmentProvider,
  getApptViewServiceCategory,
  getIntervalWithDateAndTime,
} from '../utilities/utilities'
import { CANCELED_APPOINTMENT_STATUSES_SET } from '../utilities/constants'
import {
  useSearchOOOAppts,
  useSearchApptsByProviderId,
} from '../hooks/useSearchAppointments'
import {
  actions,
  AddAdminApptFormViewState,
  EditAppointmentView,
  useReduxDispatch,
} from '../reducer'

/**
 * TYPES
 */

interface FormData {
  coveringProvider: string | null
  timeZone: string
  startDate: Date | null
  endDate: Date | null
  startTime: TimeOption | null
  endTime: TimeOption | null
  comment: string
  description: string
}

/** validation will force some values to be filled out */
interface SubmittedFormData extends FormData {
  serviceCategory: ServiceCategoryCode
  serviceType: ServiceTypeCode
  startDate: Date
  endDate: Date
  startTime: TimeOption
  endTime: TimeOption
}

/**
 * END TYPES
 */

/**
 * CONSTANTS
 */

/** some styles to stay consistent between seperate elements */
export const formGap = 2
const timezoneOptions = getTimezoneValues()
const { useCreateAppointmentMutation, useUpdateAppointmentMutation } =
  schedulingApi
/**
 * END CONSTANTS
 */

/**
 * STAND ALONE FUNCTIONS
 */

/** only including what is necessary in the form */

const apptToForm = (appt?: AppointmentFragment): FormData => {
  const [startDate, startTime]: DateTimeTuple = isoDateTimeToTuple(
    appt?.startDate,
  )
  const [endDate, endTime]: DateTimeTuple = isoDateTimeToTuple(appt?.endDate)
  return {
    startDate,
    endDate,
    startTime,
    endTime,
    timeZone: getUSTimezone(SYSTEM_TIMEZONE),
    coveringProvider:
      appt?.participants.find(
        (part) =>
          part.type?.code === AppointmentParticipantTypeCode.CoveringProvider,
      )?.actor._id || null,
    description: appt?.description || '',
    comment: '',
  }
}

const formToApptInput = (
  {
    timeZone,
    startDate,
    startTime,
    endDate,
    endTime,
    comment,
    coveringProvider,
    description,
  }: SubmittedFormData,
  providerId: string,
): Omit<AppointmentInput, 'serviceType' | 'serviceCategory' | 'timeZone'> => {
  return {
    status: AppointmentStatus.Booked,
    appointmentType: AppointmentType.Routine,
    startDate: dateTimeTupleToIsoDateStr(startDate, startTime, timeZone),
    endDate: endDate
      ? dateTimeTupleToIsoDateStr(endDate, endTime, timeZone)
      : dateTimeTupleToIsoDateStr(startDate, endTime, timeZone),
    participants: [
      {
        actor: {
          _id: providerId,
          type: ResourceType.Practitioner,
        },
        required: true,
        type: AppointmentParticipantTypeCode.PrimaryPerformer,
      },
    ].concat(
      coveringProvider
        ? {
            actor: {
              _id: coveringProvider,
              type: ResourceType.Practitioner,
            },
            required: true,
            type: AppointmentParticipantTypeCode.CoveringProvider,
          }
        : [],
    ),
    note: comment.trim(),
    description: description.trim(),
  }
}
/**
 * END STAND ALONE FUNCTIONS
 */

export default function AddEditAdminAppt({
  view,
}: {
  view: EditAppointmentView | AddAdminApptFormViewState
}) {
  const appointment = view.mode === 'edit' ? view.appointment : undefined
  const serviceCategory = getApptViewServiceCategory(view)
  const providerId =
    view.mode === 'edit'
      ? getAppointmentProvider(view.appointment)?._id!
      : view.providerId

  const { t } = useTranslation()
  const notify = useNotify()
  const dispatch = useReduxDispatch()

  const [updateAppointment, updateApptRes] = useUpdateAppointmentMutation()
  const [createAppointment, createApptRes] = useCreateAppointmentMutation()

  const defaultValues = useMemo(() => apptToForm(appointment), [appointment])
  const methods = useForm({
    defaultValues,
  })
  const isOOO = serviceCategory === ServiceCategoryCode.OutOfOffice
  const startDate = methods.watch('startDate')
  const endDate = methods.watch('endDate')
  const startTime = methods.watch('startTime')
  const endTime = methods.watch('endTime')
  const selectedCoveringProviderId = methods.watch('coveringProvider')
  const currentlySavedCoveringProviderId = defaultValues.coveringProvider
  const { hasDate, hasTime, interval, isActiveAppt } = useMemo(() => {
    return {
      hasDate: isOOO ? !!(startDate && endDate) : !!startDate,
      hasTime: isOOO
        ? !!(startDate && endDate && startTime && endTime)
        : !!(startDate && startTime && endTime),
      interval: getIntervalWithDateAndTime({
        startDate,
        startTime: startTime?.iso,
        endDate: isOOO ? endDate : startDate,
        endTime: endTime?.iso,
      }),
      isActiveAppt: appointment && isPast(new Date(appointment.startDate)),
    }
  }, [startDate, startTime, isOOO, endDate, endTime, appointment])

  const skipSearchConflictOOO =
    // skip if no covering provider selected
    !selectedCoveringProviderId ||
    // skip if we have not filled in date ranges
    !hasTime ||
    // skip if we haven't changed the covering provider from whats already saved on the OOO
    selectedCoveringProviderId === currentlySavedCoveringProviderId

  const { ownAppts } = useSearchApptsByProviderId(providerId)

  const { isFetching: isLoadingOverlapAppts, oooAppts: coveringProviderOOO } =
    useSearchOOOAppts(selectedCoveringProviderId!, skipSearchConflictOOO)

  const hasConflict: boolean = useMemo(() => {
    if (!interval) return false
    if (!coveringProviderOOO) return false
    return !!coveringProviderOOO?.some((appt) => {
      const apptInterval = {
        start: new Date(appt.startDate),
        end: new Date(appt.endDate),
      }
      return areIntervalsOverlapping(apptInterval, interval)
    })
  }, [interval, coveringProviderOOO])

  const components = useMemo(
    () => ({
      startDate: (
        <DatePicker
          disabled={isActiveAppt}
          helperText={
            isActiveAppt
              ? t('This appointment is active, start date connot be changed')
              : ''
          }
          name="startDate"
          label={t(isOOO ? 'startDate' : 'date')}
          required
          deps={['endDate']}
          fullWidth
          format="iiii, MMMM d, yyyy"
          disablePast
          closeOnSelect
          validate={(date: Date, { startTime }) => {
            const dt = dateTimeTupleToDate(date!, startTime!)
            return (
              isActiveAppt || // an active appt will have the start date disabled so the user isn't actually changing it
              isFuture(dt) ||
              t('form_invalid_field_min_date', {
                field: t('startDate'),
                min: format(new Date(), 'Pp'),
              })
            )
          }}
        />
      ),
      timeZone: (
        <Select label={t('timezone')} name="timeZone" fullWidth required>
          {timezoneOptions.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      ),
      startTime: (
        <TimePicker
          disabled={isActiveAppt}
          name="startTime"
          label={t('startTime')}
          deps={['startDate']}
          required
          disableClearable
          sx={{ minWidth: '8rem', width: '100%' }}
        />
      ),
      endDate: (
        <DatePicker
          name="endDate"
          label={t('endDate')}
          required
          validate={
            serviceCategory === ServiceCategoryCode.OutOfOffice
              ? {
                  inFuture: (date: Date, { endTime }) => {
                    const endDate = dateTimeTupleToDate(date!, endTime!)
                    return (
                      isFuture(endDate) ||
                      t('form_invalid_field_min_date', {
                        field: t('endDate'),
                        min: format(new Date(), 'Pp'),
                      })
                    )
                  },
                  startGreaterThanEnd: (date1: Date, formValues) => {
                    const date1Time = formValues.endTime
                    const date2 = formValues.startDate
                    const date2Time = formValues.startTime
                    // let the required validations catch those
                    if (!date1 || !date2 || !date1Time || !date2Time)
                      return true
                    const startDate = dateTimeTupleToDate(date2, date2Time)
                    const endDate = dateTimeTupleToDate(date1, date1Time)
                    return validate.dateOneGreaterThanDate2({
                      date1: endDate,
                      date1TArg: 'endDate',
                      date2: startDate,
                      date2TArg: 'startDate',
                      t,
                    })
                  },
                }
              : undefined
          }
          fullWidth
          format="iiii, MMMM d, yyyy"
          disablePast
        />
      ),
      endTime: (
        <TimePicker
          name="endTime"
          label={t('endTime')}
          deps={isOOO ? ['endDate'] : undefined}
          required
          fullWidth
          disableClearable
          sx={{
            minWidth: '8rem',
            width: '100%',
            ml: !isOOO ? 'auto' : undefined,
          }}
        />
      ),
    }),
    [isActiveAppt, isOOO, serviceCategory, t],
  )

  const comments = useMemo(() => {
    if (!appointment?.notes?.length) return null
    return appointment.notes
      .slice()
      .sort((a, b) => compareDesc(new Date(a.time), new Date(b.time)))
  }, [appointment?.notes])

  const filterResults = useCallback(
    (options: ProviderMinimalFragment[] | undefined) => {
      if (!hasDate) {
        return []
      }
      return (
        options?.filter((pract) => {
          if (pract._id === providerId) return false

          // only Supvervising Therapitst can be covering providers
          /*
          if (pract.employment?.role !== EmploymentRole.TherapistSupervisor) {
            return false
          }
          */
          return true
        }) || []
      )
    },
    [hasDate, providerId],
  )

  const onFormValidationFail = () => {
    notify({
      message: t('form_invalid'),
      severity: 'warning',
    })
  }

  const onSubmit = async (values: FormData) => {
    const content = formToApptInput(values as SubmittedFormData, providerId)

    if (hasConflict) {
      methods.setError('coveringProvider', {
        type: 'validate',
        message: t('api.appointment.conflictOOOSaveError'),
      })
      return
    }

    const isOverlapping =
      !interval ||
      !ownAppts ||
      !!ownAppts
        ?.filter((appt) => (appointment ? appointment._id !== appt._id : true))
        ?.filter(
          (appt) =>
            !(
              CANCELED_APPOINTMENT_STATUSES_SET.has(appt.status) ||
              appt.status === AppointmentStatus.Proposed
            ),
        )
        ?.filter((appt) =>
          serviceCategory === ServiceCategoryCode.OutOfOffice
            ? appt.serviceCategory.code === ServiceCategoryCode.OutOfOffice
            : true,
        )
        .some((appt) => {
          const apptInterval = {
            start: new Date(appt.startDate),
            end: new Date(appt.endDate),
          }
          return areIntervalsOverlapping(apptInterval, interval)
        })

    if (isOverlapping) {
      methods.setError('startDate', {
        type: 'validate',
        message: t('api.appointment.overlappingApptSaveError'),
      })
      if (serviceCategory === ServiceCategoryCode.OutOfOffice) {
        methods.setError('endDate', {
          type: 'validate',
          message: t('api.appointment.overlappingApptSaveError'),
        })
      }
      methods.setError('startTime', {
        type: 'validate',
        message: ' ',
      })
      methods.setError('endTime', {
        type: 'validate',
        message: ' ',
      })

      return
    }

    const res = await (appointment
      ? updateAppointment({
          id: appointment._id,
          content: {
            ...content,
            serviceCategory,
            serviceType: getAdminApptServiceType(serviceCategory),
          },
        })
      : createAppointment({
          content: {
            ...content,
            serviceCategory,
            serviceType: getAdminApptServiceType(serviceCategory),
          },
        }))

    if ('error' in res) {
      notify({
        message: t('api.appointment.saveFailure'),
        severity: 'error',
      })
    } else {
      notify({
        message: t('api.appointment.saveSuccess'),
        severity: 'success',
      })

      const updated =
        'createAppointment' in res.data
          ? res.data.createAppointment
          : res.data.updateAppointment

      // if we create, we want to set this page to view mode

      dispatch(
        actions.openView({
          type: 'appointmentForm',
          mode: 'edit',
          appointment: updated,
        }),
      )

      methods.reset(
        {
          ...values,
          comment: '',
        },
        {
          keepIsSubmitted: true,
          keepSubmitCount: true,
        },
      )
    }
  }

  const providerSelect = (
    <ProviderSelect
      disabled={!hasDate || isActiveAppt}
      label={t('AddEditOOOAppt.coveringProvider')}
      name="coveringProvider"
      filterOptions={filterResults}
      fullWidth
      setValueAs={(p) => p && p._id}
      parseValue={(value: string, options) =>
        options.find((o) => o._id === value) || null
      }
      helperText={
        (!skipSearchConflictOOO && (
          <Stack direction="row" alignItems="center" gap={1}>
            {isLoadingOverlapAppts ? (
              <CircularProgress size="1.5rem" />
            ) : !hasConflict ? (
              <>
                <Check color="success" />
                {t('AddEditOOOAppt.noConflictOOO')}
              </>
            ) : (
              <>
                <Close color="error" />
                {t('AddEditOOOAppt.conflictOOO')}
              </>
            )}
          </Stack>
        )) ||
        (isActiveAppt
          ? 'This appointment is active. The covering provider cannot be changed. If a new covering provider is taking over OOO responsibilities, change the end date of this appointment to the next available time option, and create a new appointment with a new covering provider.'
          : '')
      }
    />
  )

  return (
    <FormProvider {...methods}>
      <Stack
        component="form"
        sx={{ height: '100%' }}
        onSubmit={methods.handleSubmit(onSubmit, onFormValidationFail)}
      >
        <WorkflowSidebarContent>
          <Stack spacing={3}>
            <Box>
              {/** Stack applied margin css to its children that messes up the grid container  */}
              {isOOO ? (
                <Grid container spacing={formGap}>
                  <Grid item xs={12}>
                    {components.timeZone}
                  </Grid>
                  <Grid item xs={8}>
                    {components.startDate}
                  </Grid>
                  <Grid item xs={4}>
                    {components.startTime}
                  </Grid>
                  <Grid item xs={8}>
                    {components.endDate}
                  </Grid>
                  <Grid item xs={4}>
                    {components.endTime}
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={formGap}>
                  <Grid item xs={12}>
                    {components.startDate}
                  </Grid>
                  <Grid item xs={12}>
                    {components.timeZone}
                  </Grid>
                  <Grid item xs={6}>
                    {components.startTime}
                  </Grid>
                  <Grid item xs={6}>
                    {components.endTime}
                  </Grid>
                </Grid>
              )}
            </Box>

            {isOOO && (
              <>
                <Box>
                  <Typography variant="h5" gutterBottom>
                    {t('AddEditOOOAppt.coveringProvider')}
                  </Typography>
                  <Typography>
                    {t('AddEditOOOAppt.coveringProviderDescription')}
                  </Typography>
                </Box>

                {hasDate ? (
                  providerSelect
                ) : (
                  <Tooltip title={t('AddEditOOOAppt.providerSelectTooltip')}>
                    <div>{providerSelect}</div>
                  </Tooltip>
                )}
              </>
            )}
            <TextField
              name="description"
              label={t('description')}
              multiline
              fullWidth
            />
            <TextField name="comment" label={t('comment')} multiline />
            {comments && (
              <Stack gap={1}>
                {comments.map(
                  ({ text, time, authorReference: { display } }) => (
                    <Comment
                      key={time}
                      author={display}
                      text={text}
                      date={new Date(time)}
                    />
                  ),
                )}
              </Stack>
            )}
          </Stack>
        </WorkflowSidebarContent>
        <WorkflowSidebarActions>
          <Button
            variant="text"
            onClick={() =>
              isOOO
                ? dispatch(
                    actions.openView({
                      type: 'appointmentList',
                      code: ServiceCategoryCode.OutOfOffice,
                      mode: 'future',
                      providerId,
                    }),
                  )
                : dispatch(actions.closeView())
            }
          >
            {t('cancel')}
          </Button>
          <SaveButton
            isError={updateApptRes.isError || createApptRes.isError}
            isSuccess={updateApptRes.isSuccess || createApptRes.isSuccess}
            label={t('save')}
            disabled={!hasTime || isLoadingOverlapAppts}
          />
        </WorkflowSidebarActions>
      </Stack>
    </FormProvider>
  )
}
